body {
  font-family: 'Viga', 'Lato', sans-serif; 
  
  }
  


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
