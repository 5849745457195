.modal-prealerts {
    width: 40vw;
    height: 35vw;
    overflow: hidden;
}

.box-id {
    background-color: #FECD53;
    border-radius: 0.4vw;
    width: 12.6vw;
    height: 2.7vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom:1vw ;
    box-shadow: 1px 1px 17px rgba(0, 0, 0, 0.2);
}

.boxid-text {
    color: white;
    font-size: 1.4vw;
    text-align: center;
    text-shadow: 1.019px 1.019px 2.05px rgba(0, 0, 0, 0.2);
}

.modal-prealerts-title {
    color: #b3df53;
    position: relative
    
}

.modal-prealerts-content {
    position: relative;
    font-size: 1vw;
    margin-top: 15vw ;
    z-index: 1;
}

#botonCerrar {
    background-color: #5452e1;
    color: white;
    width: 19vw;
    position: relative;
}

.boton-toast-yes {
    margin-top: 0.5vw;
    background-color: #b3df53;
    color: white;
    border: none;
    border-radius: 2px;
    width: 4vw;
    font-family: 'Viga';
    cursor: pointer;
}

.boton-toast-no {
    margin-top: 0.5vw;
    background-color: #ff4d4d;
    border: none;
    color: white;
    border-radius: 2px;
    width: 4vw;
    font-family: 'Viga';
    cursor: pointer;
}

.div-boton-toast {
    display: flex;
    flex-direction: row;
    gap: 1vw;
    justify-content: center;
}

.boton-toast-no:hover {
    transform: scale(1.10);
    transition: background-color 0.3s, transform 0.3s ease;
}
.boton-toast-yes:hover {
    transform: scale(1.10);
    transition: background-color 0.3s, transform 0.3s ease;
}

@media only screen and (max-width: 600px){
    /* Modificaciones para quitar vistas web */
    .box-id{height: 5vw;
            width: 20vw;}
    .boxid-text{
        font-size: 1.7vw;
    }
}