.Title-Wuuju {
    color: #5452e1;
    font-size: 3vw;
    text-align: center;
    margin-top: 4.1vw;
}

.Subtitle-address {
    margin-top: -2vw;
    margin-bottom: 2.3vw;
    font-size: 1.6vw;
    text-align: center;
}

.Button-express {
    border-radius: 2vw;
    border: none;
    width: 60%;
    height: 2.9vw;
    color: white;
    background-color: #b3df53;
    margin-bottom: 0.8vw;
    justify-content: center;
    font-family: "Viga", serif;
    font-size: 1.2vw;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.Button-minor {
    border-radius: 2vw;
    border: none;
    width: 60%;
    height: 2.9vw;
    color: white;
    background-color: #009ad5;
    margin-bottom: 0.8vw;
    ;
    justify-content: center;
    font-family: "Viga", serif;
    font-size: 1.2vw;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.Button-major {
    border-radius: 2vw;
    border: none;
    width: 60%;
    height: 2.9vw;
    color: white;
    background-color: #ff652a;
    margin-bottom: 0.8vw;
    ;
    justify-content: center;
    font-family: "Viga", serif;
    font-size: 1.2vw;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.Button-Apple {
    border-radius: 2vw;
    border: none;
    width: 60%;
    height: 2.9vw;
    color: white;
    background-color: #0a0a0a;
    justify-content: center;
    font-family: "Viga", serif;
    font-size: 1.2vw;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.typography-content {
    margin-top: 2vw;
    font-size: 2vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.center-content {
    text-align: center;
}

.center-container .whatsapp-link {
    margin-right: 5px;
}

.center-container .css-i4bv87-MuiSvgIcon-root {
    font-size: 1.5vw;
}

.center-container button {
    margin: 0 auto 0.8vw;
}

@media only screen and (max-width: 600px) {

    /* Modificaciones para quitar vistas web */
    .Title-Wuuju {
        margin-top: 5vw;
        margin-bottom: 5vw;
        font-size: 10vw;
    }
    .Subtitle-address{
        font-size: 5vw;
        margin-bottom: 10VW;
    }

    .button-container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }
    .Button-express,
    .Button-minor,
    .Button-major,
    .Button-Apple {
        border-radius: 40px;
        width: calc(100% - 10px); 
        height: 12vw;  
        font-size: 3.3vw;
        justify-content: center;
    }
    .center-container .css-i4bv87-MuiSvgIcon-root {
        font-size: 5.5vw;
    }
    .typography-content {
        margin-top: 11vw;
    }
  
}