.tooltip-container {
    position: relative;
    display: inline-block;
  }
.tooltip-img-open {
  cursor: pointer;
  width: 1.5vw;
  margin-top: 1.5vw;
  margin-left: 1vw;
}
  .tooltip-content {
    text-wrap: nowrap;
    width: 11vw;
    height: 4vw;
    background-color: #f9f9f9;
    color: gray;
    text-align: left;
    padding: 2.5vw;
    border-radius: 15px;
    position: absolute;
    z-index: 1;
    left: 120%;
    top: -50%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border: 1px solid #6CE9B1;
  }
  
  .tooltip-content h2 {
    margin-top: -1.2vw;
    margin-bottom: 0;
    font-size: 0.8vw;
    margin-left: 0vw;
  }
  
  .tooltip-content ul {
    margin: 0;
    padding-left: 0.17vw;
    font-size: 0.8vw;
    list-style-type: none;

  }
  
  .close-tooltip {
    position: absolute;
    top: 5px;
    right: 0.6vw;
    cursor: pointer;
    width: 1.2vw;
  }
  

  @media only screen and (max-width: 600px) {
    .tooltip-img-open {
      width: 5vw;
      margin-top: 7vw;
    }
    .tooltip-content {
      left: -52vw;
      height: 14vw;
      width: 35vw;
      padding: 3vw;
      border-radius: 10px;
    }
    .tooltip-content h2 {
      font-size: 2vw;
      margin-left: 2.5vw;
    }
    .tooltip-content ul {
      font-size: 2vw;
      margin-left: 2.5vw;
    }
    .close-tooltip{
      width: 2.5vw;
      margin-right: 1vw;
    }
  }