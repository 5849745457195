.final-price-message-container{
    height: 11vw !important;
}

/* Textos */
.content-title-text {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 1vw;
    position: relative;
}

/* Titulo */
.title-text {
    font-size: 1.7vw;
    font-weight: bold;
    color: #5452E1;
    display: block;
    margin: 0;
}
.title-text2 {
    font-size: 1.5vw;
    font-weight: bold;
    color: white;
    display: block;
    margin: 0;
    background-color: #5452E1;
    border-radius: 0.4vw;
    width: 32vw;
  
}

.modalidad-container {
    margin-top: 1vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.modalidad-text {
    font-size: 1vw;
    color: #888;
}

.servicio-container {
    border: 1px solid #5452E1;
    border-radius: 1.3vw;
    height: 2vw;
    padding: 0.2vw;
    width: 10vw;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.servicio-text {
  font-size: 1vw;
  color: #5452E1;
}
/* Total */
.total {
    font-size: 5.5vw;
    color: #b3df53;
    display: flex;
    justify-content: center;
    font-weight: bolder;
    text-shadow: 2px 2px 2px gray;
    margin-top: 0.2vw;
}
.undertext-new {
    text-align: center;
    margin-top: -0.5vw;
}
.under-text {
    padding-top: 0.4vw;
    justify-content: center;
    color: #5452E1;
    margin-bottom: -3vw;
    display: flex;
    font-size: 1.5vw;
}

.text-container-p {
    margin-top: 2vw;
    text-align: center;
    line-height: 1vw;
    border: 0.5px solid #5452E1;
    border-radius: 1vw ;
    width: 31vw;
    padding: 0.5vw;
    position: relative;
    left: 17%;
}

.boton-regresar {
    font-size: 0.9vw;
}
.parrafo-sorpresa {
    margin-bottom: 0;
    font-size: 0.8vw;
    text-align: center;
    line-height: 0.5vw;

}

.paragraph1 {

font-size: 1vw;

}
.paragraph-color{
    color:#5452E1;
}

#boton2 {
    background-color: #5452E1;
    color: white;
    width:31vw ;
    height: 2vw;
    font-size: 1.2vw;
    font-family: "Viga";
    border-radius: 1vw;

}

.boton2-container{
    margin-top: 0.5vw;
    margin-bottom: 1vw;
}

.final-text {
    text-align: center;
    margin-bottom:0.5vw ;
    font-size: 1vw;
}

.final-text span {
    color: grey;
}
.disclaimer {

    font-size: 0.9vw;
    color: #888;
    text-align: center;
}

.alert-responsive {
   width: 2vw;
   transform: rotate(-11deg);
   position: relative;
   margin-top: 0%;
   top: 3vw;
   margin-bottom: 0%;
    left: 48%;
    z-index: 1;
}

/* Desplegable */



p {
    display: block;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}
h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: lighter;
}
.textoClickeable {
    cursor: pointer;
    color: #5452e1;
    font-weight: bolder;
    font-family: 'Viga', sans-serif;
}
.recuadro {
    position: fixed;
    height: 37vw;
    width: 37vw;
    padding: 20px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 30px;
    box-shadow: 1px 1px 17px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    text-align: center;
    font-family: 'Viga', sans-serif;
    overflow: hidden;
}

.tituloRecuadro {
    font-size: 2vw;
    font-weight: bolder;
    color: #5452E1;
    font-family: 'Viga', sans-serif;
    white-space: nowrap; /* Evita saltos de línea */
}

.textoUsd {
    font-size: 1.2vw;
    color: gray;
    font-family:'Viga', sans-serif;
    text-align: center;
    margin-bottom: 1.7vw;
}

#botonCerrar.botonListo {
    top: 85%;
    border-radius: 20px;
    height: 3vw;
    width: 22vw;
    background-color: #b3df53;
    margin-top: 10px;
    align-self: center;
    font-family: 'Viga', sans-serif;
    font-weight: bolder;
    font-size: 2.5vw;
    position: absolute;
    color: white; 
    left: 50%; 
    transform: translateX(-50%); 
}

.subtitle {
    margin: 0;
    display: flex;
    align-items: baseline; 
    margin-left: 3%;
    margin-top: -2vw;
}

.subtitulo {
    font-size: 1.5vw;
    color:#5452E1;
    text-align: left;
    margin-right: 0.7vw;
    white-space: nowrap;
}

.contenido {
    font-family: 'Viga', sans-serif;
    font-size: 1.5vw;
    color: black;
    font-weight: bolder;
    white-space: nowrap;
}

.contenido-tachado {
    font-family: 'Viga', sans-serif;
    font-size: 1.6vw;
    color: black;
    font-weight: bolder;
    white-space: nowrap;
    text-decoration: line-through;
}

.gratisText {
    font-size: 1.6vw;
    font-weight: bolder;
    color: #b3df53;
    text-decoration: none;
    margin-left: 0.5vw;
}

@media only screen and (max-width: 600px) {
    .tituloRecuadro{
        font-size: 5vw;
    }

    .subtitulo{
        font-size: 4vw;
    }

    .contenido{
        font-size: 4vw;
    }

    .textoUsd{
        font-size: 3vw;
    }

    .contenido-tachado{
        font-size: 4vw;
    }

    .gratisText{
        font-size: 4vw;
    }

    .modalidad-container{
        margin-top: 11vw;
    }

    .modalidad-text {
        font-size: 2.8vw;
    }

    .servicio-container{
        width: 30vw;
        height: 4vw;
        border: 2px solid #5452E1;
        border-radius: 9vw;
        text-align: center;
        padding: 2vw;
    }

    .servicio-text {
        font-size: 2.5vw;
    }

    .boton-regresar{
        font-size: 2.5vw;
    }

    .content-title-text {
        margin-top: 3vw;
    }

    .title-text {
        font-size: 4.6vw;
        
    }

    .title-text2 {
        font-size: 3.7vw;
        width: 75vw;
        height: 4vw;
        border-radius: 1vw;
        text-align: center;
        padding: 1vw;
    }

    .total {
        font-size: 12.5vw;
        margin-top: 4vw;
    }

    .paragraph1  {
        font-size: 2.6vw;
    }

    .under-text {
        margin-top: 2vw;
        font-size: 4vw;
        margin-bottom: 2vw;
    }

    .alert-responsive{
        width: 6vw;
        position: relative;
        top: 4.5vw;
    }

    .text-container-p {
        width: 60vw;
        left: 11%;
        padding: 1vw;
        border: 1.5px solid #5452E1;
    }

    .parrafo-sorpresa {
        font-size: 2.5vw;
        line-height: 1;
        
    }

    #boton2{
        width: 80vw;
        font-size: 3.5vw;
        height: 7vw;
        border-radius: 3vw;
    }

    .boton2-container {
        margin-top: 4vw;
    }

    .final-text {
        margin-top: 4vw;
        font-size: 3.6vw;
    }

    .disclaimer {
        margin-top: 7vw;
        font-size: 3vw;
    }
}