.new-password-message-text {
    justify-content: center;
    align-items: center;
    color: white;
    margin-left: 14%;
    margin-right: 18%;
    margin-top: 18%;
    font-size: 1.6vw;
    font-family: 'Lato', sans-serif;
    text-shadow: 1.019px 1.019px 2.05px rgba(0, 0, 0, 0.2);
}

.recommendations-text{
    font-family: 'Lato', sans-serif;
    color: #FFFF00
}
.new-password-img {
width: 14%;
height: auto;
position: absolute;
top: 73%;
z-index: 4;
left:12%;
border: none;
}

@media only screen and (max-width: 600px){

    .new-password-img {
        display: none;
    }
}
