.navbar-buttom {
    position: fixed;
    right: 3%;
    bottom: 3%;
    z-index: 10;
}

.menu-icon-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0); /* Color de fondo semitransparente */
    backdrop-filter: blur(4px); /* Desenfoque del fondo */
    z-index: 11;
}

.nav-bar {
    display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 5%;
    z-index: 12;
    left: 50%;
    transform: translate(100%, 0);
    opacity: 0;
    visibility: hidden;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

.nav-bar.open {
    transform: translate(-50%, 0);
    opacity: 1;
    visibility: visible;
}

.navbar-close {
    height: 8vw;
    background-color: #a2a4f9;
    border-radius: 3vw 3vw 0 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}

.img-close-navbar {
    width: 2vw;
    cursor: pointer;
    position: absolute;
    top: 8%;
    right: 3%;
}

.nav-links {
    display: flex;
    background-color: #ffffff;
    height: 15vw;
    border-radius: 0 0 3vw 3vw;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    padding: 0 2.5vw;
}

.nav-links a {
    font-size: 2vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 2vw;
}

.nav-links span {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: gray;
}

.img-navbar {
    width: 7vw;
    position: relative;
}

/* Estilo para el enlace activo */
.nav-links a img {
    transition: transform 0.3s ease;
}

.nav-links a span {
    transition: color 0.2s ease, transform 0.2s ease;
}

.nav-links a.active {
    transform: translate(0, -15%);
}

.nav-links a.active img {
    transform: scale(1.8);
}

.nav-links a.active span {
    transform: scale(1.2);
    margin-top: 4vw;
}

.nav-links a.active span {
    color: #5452e1;
}

.nav-links a.disabled span {
    color: #cccccc;
}

.nav-links a.disabled{
    pointer-events: none;
    color: #cccccc;
    cursor: default;
}

.nav-links a.disabled img {
    opacity: 0.5;
}

.menu-text {
    color: #5452e1;
}

.menu-icon {
    width: 10vw;
    cursor: pointer;
}

@media (min-width: 601px) {
    #navbar-movil {
        display: none;
    }
}